// Libraries
import camelcaseKeys from 'camelcase-keys';
import { isArray, isNumber, isObject } from 'underscore';
import { currentLanguage } from 'i18n';

// Errors
import { logError } from 'common/utils/errors';
import { jsonaDeserialize } from 'common/utils/helpers';

// Utils
import { getUserToken, userAuthenticated } from 'ideation/utils/userAuthenticate';

const API_BASE_URL = '/api/v3';
const JSON_API_MEDIA = 'application/vnd.api+json';
const DEFAULT_HEADERS = {
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
  'Content-Language': currentLanguage,
  accept: JSON_API_MEDIA,
};

export class FetchError extends Error {
  constructor(message, status, statusText) {
    super(message);
    this.name = 'FetchError';
    this.status = status;
    this.statusText = statusText;
  }
}

function defaultHeaders() {
  if (userAuthenticated()) {
    return { ...DEFAULT_HEADERS, authorization: `bearer ${getUserToken()}` };
  }

  return DEFAULT_HEADERS;
}

function queryString(originalParams) {
  const params = new URLSearchParams();

  for (const [paramKey, paramValue] of Object.entries(originalParams)) {
    if (!paramValue && !isNumber(paramValue)) continue;

    if (isObject(paramValue) && !isArray(paramValue)) {
      for (const [key, value] of Object.entries(paramValue)) {
        params.append(`${paramKey}[${key}]`, value);
      }
    } else {
      params.append(paramKey, paramValue);
    }
  }

  return params.toString();
}

export function privateApiUrl(endpoint, params = {}) {
  let url = `${API_BASE_URL}/private/${endpoint}`;

  if (Object.keys.length) {
    url += `?${queryString(params)}`;
  }

  return url;
}

export function publicApiUrl(endpoint, params = {}) {
  return `${API_BASE_URL}/public/${endpoint}?${queryString(params)}`;
}

//  eslint-disable-next-line
async function request(url, method = 'GET', headers = {}, body) {
  try {
    const response = await fetch(url, {
      headers: { ...defaultHeaders(), ...headers },
      method,
      body,
    });

    if (response.ok) {
      if (response.status === 204) {
        return '';
      }

      const json = await response.json();

      if (json.data) {
        const deserialized = jsonaDeserialize(json);
        const links = json.links && camelcaseKeys(json.links, { deep: true });
        const meta = json.meta && camelcaseKeys(json.meta, { deep: true });

        return { deserialized, links, meta };
      }

      return json;
    }

    const error = new FetchError(
      `${response.status} code returned for ${method} ${url}`,
      response.status,
      response.statusText
    );

    try {
      error.body = await response.json();
      throw error;
    } catch {
      throw error;
    }
  } catch (error) {
    logError(error);
    throw error;
  }
}

export function getRequest(url) {
  return request(url, 'GET');
}

export function postRequest(url, body) {
  return request(url, 'POST', { 'content-type': JSON_API_MEDIA }, JSON.stringify(body));
}

export function putRequest(url, body) {
  return request(url, 'PUT', { 'content-type': JSON_API_MEDIA }, JSON.stringify(body));
}

export function deleteRequest(url, body) {
  return request(url, 'DELETE', { 'content-type': JSON_API_MEDIA }, JSON.stringify(body));
}
