// Libraries
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getUserToken = () => {
  return cookies.get(gon.jwtCookieName) || '';
};

export const userAuthenticated = () => {
  return Boolean(getUserToken());
};
